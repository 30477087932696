<template>
  <div class="site-navigation">
    <nav id="site-navigation" aria-label="Main Menu">
      <DesktopMenu :menu="menus" />
    </nav>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { cloneDeep } from 'lodash'
import stockMenus from './menus.json'
import { giftingItemsMenu, occasionsMenu } from '~/utils/metadata'

export default {
  components: {
    DesktopMenu: () => import('~/components/Header/NavigationMenu/DesktopMenu'),
  },
  computed: {
    ...mapGetters(['occasions', 'gifting_menu_items', 'constance']),
    menus() {
      const menus = cloneDeep(stockMenus)
      // Add the Occasions to the menu
      menus.items.splice(
        1,
        0,
        occasionsMenu(this.occasions, this.constance.EP1_ENABLE === 'True')
      )
      // Add gifting items to the menu
      menus.items.forEach((itemsMenu) => {
        if (itemsMenu.text === 'Gifting') {
          itemsMenu.items.push(...giftingItemsMenu(this.gifting_menu_items))
        }
      })
      return menus
    },
  },
}
</script>
